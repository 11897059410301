(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('AgenceDialogController', AgenceDialogController);

    AgenceDialogController.$inject = ['$scope', "leafletMapEvents",'$stateParams', '$uibModalInstance', 'entity', 'Agence', 'EntiteParente','WaterNetwork'];

    function AgenceDialogController ($scope, leafletMapEvents, $stateParams, $uibModalInstance, entity, Agence, EntiteParente,WaterNetwork) {
        var vm = this;
        vm.agence = entity;
        vm.entiteparentes = EntiteParente.query();
        vm.waterNetworks = WaterNetwork.query();
        vm.timezones = Agence.getTimezones();
        
        vm.updateCoord = function(){
          	
        	//On remet à 0 en cas d'erreur sur le champs.
        	if(! vm.agence.lat ){
        		vm.agence.lat = 0;
        	}
        	if(! vm.agence.lon){
        		vm.agence.lon = 0;
        	}
        	
	        angular.extend($scope, {
	            defaults: {
	                scrollWheelZoom: false
	            },
//	            events: {
//	                map: {
//	                    enable: [ 'drag', 'click'],
//	                    logic: 'emit'
//	                }
//	            },
	            markers: {
	                Area: {
	                    lat: vm.agence.lat,
	                    lng: vm.agence.lon,
//	                    message: "This is Madrid. But you can drag me to another position",
	                    focus: true,
	                    draggable: true
	                }
	            }
	        })
        }
        
        vm.load = function(id) {
            Agence.get({id : id}, function(result) {
                vm.agence = result;

            	vm.updateCoord ();
               
                
            });
        };
        
        if(entity && entity.$promise){
            entity.$promise.then(function(value) {
            	vm.updateCoord ();
            });	
        }else{
        	vm.updateCoord();
        }

        $scope.$on("leafletDirectiveMarker.dragend", function(event, args){
        	 vm.agence.lat = args.model.lat;
        	 vm.agence.lon = args.model.lng;
        });

//        
//        $scope.$on('leafletDirectiveMap.drag', function(event){
//            $scope.eventDetected = "Drag";
//        });
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:agenceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.agence.id !== null) {
                Agence.update(vm.agence, onSaveSuccess, onSaveError);
            } else {
                Agence.save(vm.agence, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateDebutDemarrage = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
